import "./App.css";
import Home from "./Components/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/Login/Login";
import UserContext from "./UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ProtectedRoutes from "./Components/ProtectedRoutes";
// import PYQExplorerDashboard from "./Components/PYQExplorer/PYQExplorerDashboard";
// import PYQExplorerDashboard2 from "./Components/PYQExplorer/PYQExplorerDashBoard2";
// import SavedForLaterDashboard from "./Components/SavedForLater/SavedForLaterDashboard";
import { useEffect, useState, Redirect, lazy, Suspense } from "react";
import WidgetContext from "./WidgetContext";
import CalendarContext from "./CalendarContext";
// import PYQInterviewDashboard from "./Components/PYQInterview/PYQInterviewDashboard";
import CssBaseline from "@mui/material/CssBaseline";
// import MockInterviewDashboard from "./Components/MockInterview/MockInterviewDashboard";
import SidebarContext from "./sidebarContext";
// import PYQFastTrack from "./Components/PYQFastTrack/PYQFastTrackDashboard2";
// import QuestionsDashboard from "./Components/Questions/QuestionsDashboard";
import BannerContext from "./bannerContext";
// import ReaderDashboard from "./Components/PYQExplorer/ReaderDashboard";
// import NewsenseDashboard from "./Components/Newsense/NewsenseDashboard";
// import AIAnswerReviewDashboard from "./Components/AIAnswerReview/AIAnswerReviewDashboard";
// import ProtectedPremiumRoutes from "./Components/ProtectedPremiumRoutes";
// import ProfileDashboard from "./Components/Profile/ProfileDashboard";
// import FullCopyEvaluationDashboard from "./Components/FullCopyEvaluation/FullCopyEvaluationDashboard";
// import BlogPostDashboard from "./Components/BlogPost/BlogPostDashboard";
// import MainsQuestionAnswer from "./Components/UpscMainsExam/MainsQuestionAnswer";
// import Support from "./Components/Support/Support";
// import TermsOfService from "./Components/TermsOfService/TermsOfService";
// import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
// import Cancellation from "./Components/Cancellation/Cancellation";
// import SubdomainLeftPain from "./Components/LeftPane/SubdomainLeftPane";
import AudioDataArrayContext from "./AudioDataArrayContext";
import PdfDataArrayContext from "./PDFContext";
// import AIAnswerReviewNew from "./Components/AIAnswerReviewNew/index";
// import AIAnswerReviewB2B from "./Components/AIAnswerReviewB2B/index";
import AIAnswerReviewB2C from "./Components/AIAnswerReviewB2C/index";
// import PdfEditorPage from './Components/PdfEditorPage/index';
// import PdfViewerPage from './Components/PdfViewerPage/index';
// import Pricing from './Components/PricingPage/Pricing';
// import FlashCardDashboard from './Components/FlashCard';
// import FollowUp from "./Components/FollowUp";
// import FlashCardViewer from './Components/FlashCardViewer';
// import NewsArticle from './Components/NewsArticle';
// import NewsArticleUtility from './Components/NewsArticleUtility';
// import NewsArticleDashboard from './Components/NewsArticleDashboard';
// import NewsArticleCard233 from './Components/NewsArticleCard/newsArticle223';
// import NewsArticleCard243 from "./Components/NewsArticleCard/newsArticle243";
// import NewsArticleCard263 from "./Components/NewsArticleCard/newsArticle263";
// import NewsArticlePage from "./Components/NewsArticlePage";
// import Dashboard from './Components/Dashboard';
// import  N360Dashboard  from "./Components/Newsense/N360Dashboard";
// import Landing from "./Components/Landing/Landing";
import ScrollToTop from "./Components/ScrollToTop";
// import Rattafai from "./Components/Rattafai/Rattafai";
// import RattafaiViewer from "./Components/RattafaiViewer/RattafaiViewer";
import QuestionTemplate from './Components/QuestionTemplate';
import Loaderb2c from "./Components/AIAnswerReviewB2C/Loaderb2c";
import PdfCrop from './Components/PdfCrop';
import { PrimeReactProvider } from 'primereact/api';
import SummaryBusinessDashboard from './Components/SummaryBusiness';
import PdfPageViewer from "./Components/PageView/PdfPageViewer";
import PaymentSuccess from './Components/PaymentSuccess';
import PaymentFailure from './Components/PaymentFailure';
import Refund from "./Components/Refund";

const AIAnswerReviewB2B = lazy(()=>import("./Components/AIAnswerReviewB2B/index"));
const Landing = lazy(()=>import("./Components/Landing/Landing"));
const PYQExplorerDashboard2 = lazy(()=>import("./Components/PYQExplorer/PYQExplorerDashBoard2"));
const SavedForLaterDashboard = lazy(()=>import("./Components/SavedForLater/SavedForLaterDashboard"));
const MockInterviewDashboard = lazy(()=>import("./Components/MockInterview/MockInterviewDashboard"));
const N360Dashboard = lazy(()=>import("./Components/Newsense/N360Dashboard"));
const Rattafai = lazy(()=>import("./Components/Rattafai/Rattafai"));
const RattafaiViewer = lazy(()=>import("./Components/RattafaiViewer/RattafaiViewer"));
const PYQFastTrack = lazy(()=>import("./Components/PYQFastTrack/PYQFastTrackDashboard2"));
const QuestionsDashboard = lazy(()=>import("./Components/Questions/QuestionsDashboard"));
const AIAnswerReviewDashboard = lazy(()=>import("./Components/Rattafai/Rattafai"));
const ProfileDashboard = lazy(()=>import("./Components/Profile/ProfileDashboard"));
const BlogPostDashboard = lazy(()=>import("./Components/BlogPost/BlogPostDashboard"));
const MainsQuestionAnswer = lazy(()=>import("./Components/UpscMainsExam/MainsQuestionAnswer"));
const MainsQuestionAnswer2024 = lazy(()=>import("./Components/UpscMainsExam2024/MainsQuestionAnswer"));
const Pricing = lazy(()=>import("./Components/PricingPage/Pricing"));
const PdfEditorPage = lazy(()=>import("./Components/PdfEditorPage/index"));
const PdfViewerPage = lazy(()=>import("./Components/PdfViewerPage/index"));
const NewsArticle = lazy(()=>import("./Components/NewsArticle"));
const NewsArticleUtility = lazy(()=>import("./Components/NewsArticleUtility"));
const NewsArticleDashboard = lazy(()=>import("./Components/NewsArticleDashboard"));
const NewsArticleCard233 = lazy(()=>import("./Components/NewsArticleCard/newsArticle223"));
const NewsArticleCard243 = lazy(()=>import("./Components/NewsArticleCard/newsArticle243"));
const NewsArticleCard263 = lazy(()=>import("./Components/NewsArticleCard/newsArticle263"));
const NewsArticlePage = lazy(()=>import("./Components/NewsArticlePage"));
const Dashboard = lazy(()=>import("./Components/Dashboard"));
const Support = lazy(()=>import("./Components/Support/Support"));
// const PaymentFailure = lazy(()=>import("./Components/PaymentFailure"));
const TermsOfService = lazy(()=>import("./Components/TermsOfService/TermsOfService"));
const PrivacyPolicy = lazy(()=>import("./Components/PrivacyPolicy/PrivacyPolicy"));
const Cancellation = lazy(()=>import("./Components/Cancellation/Cancellation"));
const FollowUp = lazy(()=>import("./Components/FollowUp"));
const PdfPageView =lazy(()=>import("./Components/PageView/PdfPageView"));
// const Abc = lazy(()=>import("./Components/Refund"));
// const Refunds = lazy(()=>import("'./Components/Refunds/index"));
const DisplayComponent = lazy(()=>import("./Components/DisplayComponent"));

function App() {
  const userState = useState({});
  const widgetState = useState({});
  const calendarState = useState({});
  const sidebarContext = useState({});
  const [audioDataArray, setAudioDataArray] = useState([]);
  const [pdfData, setPdfData] = useState({
    answer_sheet_status: [],
    stats: [],
  });
  localStorage.setItem("showBanner", true);

  const bannerContext = useState({
    showBanner: localStorage.getItem("showBanner"),
  });

  const responseFromGoogleLogin = (data) => {
    // setLoggedInUser(data);
  };

  // console.log("window location object: ", window.location);

  return (
    <GoogleOAuthProvider clientId="553125461546-ti2e6ul3g3n5ik3ohm2fr7ojoqebrlhq.apps.googleusercontent.com">
      <UserContext.Provider value={userState}>
        <WidgetContext.Provider value={widgetState}>
          <CalendarContext.Provider value={calendarState}>
            <SidebarContext.Provider value={sidebarContext}>
              <BannerContext.Provider value={bannerContext}>
              <AudioDataArrayContext.Provider value={[audioDataArray, setAudioDataArray]}>
              <PdfDataArrayContext.Provider value={[pdfData, setPdfData]}>
              <PrimeReactProvider value={{ unstyled: false, pt: {} }}>
                <Suspense fallback={<Loaderb2c />} >
                <Router>
                  <CssBaseline />
                  <ScrollToTop />
                  {
                    // narayana.collectorbabu.com
                    // dev
                  }
                  {window.location.host.split(".")[0] === "narayana" ? (
                    <Routes>
                      {["/ai-answer-review", "/", "/home"].map((path) => {
                        return (
                          <Route
                            exact
                            path={path}
                            element={
                              <AIAnswerReviewDashboard
                                responseFromGoogleLogin={
                                  responseFromGoogleLogin
                                }
                              />
                            }
                            key={path}
                          ></Route>
                        );
                      })}
                      <Route
                        exact
                        path="/login"
                        element={
                          <Login
                            responseFromGoogleLogin={responseFromGoogleLogin}
                          />
                        }
                      ></Route>
                      <Route
                        exact
                        path="/profile"
                        element={
                          <ProtectedRoutes
                            Component={ProfileDashboard}
                            path="/profile"
                            responseFromGoogleLogin={responseFromGoogleLogin}
                          />
                        }
                      ></Route>

                      <Route
                        exact
                        path="/upsc-notes-zero"
                        element={<BlogPostDashboard />}
                      ></Route>
                      <Route
                        exact
                        path="/upsc-mains-2023-essay-gs-questions-model-answers"
                        element={<MainsQuestionAnswer />}
                      ></Route>
                      <Route
                        exact
                        path="/upsc-mains-2024-essay-gs-questions-model-answers"
                        element={<MainsQuestionAnswer2024 />}
                      ></Route>
                      <Route path="/support" element={<Support />}></Route>
                      <Route
                        path="/terms-of-service"
                        element={<TermsOfService />}
                      ></Route>
                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                      ></Route>
                      <Route
                        path="/cancellation"
                        element={<Cancellation />}
                      ></Route>

                     
                   
                    </Routes>
                  ) : (
                    <Routes>
                      <Route
                        exact
                        path={"/upsc-dashboard"}
                        element={
                          <Home
                            responseFromGoogleLogin={responseFromGoogleLogin}
                          />
                        }
                      ></Route>
                      ;
                      <Route
                        exact
                        path="/login"
                        element={
                          <Login
                            responseFromGoogleLogin={responseFromGoogleLogin}
                          />
                        }
                      ></Route>
                      {["/360-reader"].map((path) => {
                        return (
                          <Route
                            exact
                            path={path}
                            element={
                              <N360Dashboard
                                responseFromGoogleLogin={
                                  responseFromGoogleLogin
                                }
                              />
                            }
                            key={path}
                          ></Route>
                        );
                      })}
                      <Route
                        exact
                        path="/savedforlater"
                        element={
                          <ProtectedRoutes
                            Component={SavedForLaterDashboard}
                            path="/savedforlater"
                            responseFromGoogleLogin={responseFromGoogleLogin}
                          />
                        }
                      ></Route>
                      {["/pyqexplorer", "/pyqexplorer/:subject"].map((path) => {
                        return (
                          <Route
                            path={path}
                            key={path}
                            element={<PYQExplorerDashboard2 />}
                          ></Route>
                        );
                      })}
                      
                      {/* <Route exact path="/pyqinterview" element={<PYQInterviewDashboard />}></Route> */}
                      {["/upsc-ias-mock-interview", "/mockinterview"].map(
                        (path) => {
                          return (
                            <Route
                              exact
                              path={path}
                              key={path}
                              element={
                                <MockInterviewDashboard
                                  responseFromGoogleLogin={
                                    responseFromGoogleLogin
                                  }
                                />
                              }
                            ></Route>
                          );
                        }
                      )}
                      {[
                        "/pyqfasttrack",
                        "/pyq-fast-track",
                        "/pyqfasttrack/:slug",
                        "/pyq-fast-track/:slug",
                      ].map((path) => {
                        return (
                          <Route
                            exact
                            path={path}
                            key={path}
                            element={
                              <PYQFastTrack
                                responseFromGoogleLogin={
                                  responseFromGoogleLogin
                                }
                              />
                            }
                          ></Route>
                        );
                      })}
                      {
                        // topper_answer may not be alaways avaialble so handle it
                        // 300 word limit in manual input answer review
                        // for prem users at root url show dropdown value default "Select day" dont show numeric value of any specific day
                        // there is no day 0 for the dropdown
                        // 2000 chars
                        // below is day 0
                        // rouute localhost:10000/ai-answer-review blocked by paywall not login
                        // ai-answer-review-day-15... blocked by login and paywall
                      }
                      <Route
                        path="/questions/:slug"
                        element={
                          <QuestionsDashboard
                            responseFromGoogleLogin={responseFromGoogleLogin}
                          />
                        }
                      ></Route>
                      {["/ai-answer-review", "/", "/home"].map((path) => {
                        return (
                          <Route
                            exact
                            path={path}
                            element={
                              <AIAnswerReviewB2C
                                responseFromGoogleLogin={
                                  responseFromGoogleLogin
                                }
                              />
                            }
                            key={path}
                          ></Route>
                        );
                      })}
                      {/* {new Array(1).fill(0).map((el, index) => {
                  return (
                    <Route
                      exact
                      key={index}
                      path={/ai-answer-review-day-${index + 1}}
                      element={
                        <ProtectedPremiumRoutes
                          Component={AIAnswerReviewDashboard}
                          path={/ai-answer-review-day-${index + 1}}
                          responseFromGoogleLogin={responseFromGoogleLogin}
                        />
                      }></Route>
                  );
                })} */}
                      <Route
                        exact
                        path="/profile"
                        element={
                          <ProtectedRoutes
                            Component={ProfileDashboard}
                            path="/profile"
                            responseFromGoogleLogin={responseFromGoogleLogin}
                          />
                        }
                      ></Route>
                      {/* <Route
                  exact
                  path="/full-copy-evaluation"
                  element={
                    <ProtectedRoutes
                      Component={FullCopyEvaluationDashboard}
                      path="/full-copy-evaluation"
                      responseFromGoogleLogin={responseFromGoogleLogin}
                    />
                  }></Route> */}
                      {
                        // TODO: User proper names in the route
                      }
                      <Route
                        exact
                        path="/upsc-notes-zero"
                        element={<BlogPostDashboard />}
                      ></Route>
                      <Route
                        exact
                        path="/upsc-mains-2023-essay-gs-questions-model-answers"
                        element={<MainsQuestionAnswer />}
                      ></Route>
                      <Route
                        exact
                        path="/upsc-mains-2024-essay-gs-questions-model-answers"
                        element={<MainsQuestionAnswer2024 />}
                      ></Route>
                      <Route path="/support" element={<Support />}></Route>
                      <Route
                        path="/terms-of-service"
                        element={<TermsOfService />}
                      ></Route>
                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                      ></Route>
                      <Route
                        path="/cancellation"
                        element={<Cancellation />}
                      ></Route>
                       <Route
                        path="/refund"
                        element={<Refund />}
                      ></Route>
                      <Route path="/pricing" element={<Pricing/>} >
                      </Route>
                      <Route path="/landing" element={<Landing/>} >
                      </Route>
                      <Route path="/pdf-crop" element={<PdfCrop/>} >
                      </Route>
                         {/* <Route
                        path="/ai-answer-review-new"
                        element={<AIAnswerReviewNew />}
                      ></Route> */}
                        <Route
                        path="/ai-answer-review-b2b"
                        element={<AIAnswerReviewB2B responseFromGoogleLogin={responseFromGoogleLogin} />}
                        
                      ></Route>
                       <Route
                        path="/question-template"
                        element={<QuestionTemplate responseFromGoogleLogin={responseFromGoogleLogin} />}
                        
                      ></Route>
                      <Route path="/follow-up" responseFromGoogleLogin={responseFromGoogleLogin} element={<FollowUp/>}></Route>
                        <Route
                        path="/dashboard"
                        element={<Dashboard responseFromGoogleLogin={responseFromGoogleLogin} />}></Route>
                         <Route
                        path="/payment-success"
                        element={<PaymentSuccess responseFromGoogleLogin={responseFromGoogleLogin} />}></Route>
                        <Route
                        path="/payment-failure"
                        element={<PaymentFailure responseFromGoogleLogin={responseFromGoogleLogin} />}></Route>
                        <Route
                        path="/summary-business/:id?"
                        element={<SummaryBusinessDashboard responseFromGoogleLogin={responseFromGoogleLogin} />}></Route>
                  <Route path="/edit/:mains_answer_sheet_hash/:user_id/:pdf_name"  element={<PdfEditorPage responseFromGoogleLogin={responseFromGoogleLogin}/>} >
                  </Route>
                  <Route path="/view/:id/:user_id/:pdf_name"  element={<PdfViewerPage responseFromGoogleLogin={responseFromGoogleLogin}/>} >
                  </Route>
                  <Route path="/flash-card"  element={<Rattafai responseFromGoogleLogin={responseFromGoogleLogin}/>}/>
                    <Route path="/flashcards/get_flashcards/:fcId"  element={<RattafaiViewer responseFromGoogleLogin={responseFromGoogleLogin}/>} ></Route>
                    <Route path="/news" element={<NewsArticle responseFromGoogleLogin={responseFromGoogleLogin}/>}></Route>
                    <Route path="/news-utility" element={<NewsArticleUtility responseFromGoogleLogin={responseFromGoogleLogin}/>}></Route>
                    <Route path="/news/2024/03/22/vechur-cow-interest-rates-gig-worker-solar-waste-ivf" element={<NewsArticleDashboard responseFromGoogleLogin={responseFromGoogleLogin}/>}></Route>
                    <Route path="/news/2024/03/23/bangalore-water-happiness-fake-news-nuclear-power-dark-sky-ladakh" element={<NewsArticleCard233 responseFromGoogleLogin={responseFromGoogleLogin}/>}></Route>
                    <Route path="/news/2024/03/24/betting-gambling-modi-bhutan-moscow-isro-rlv" element={<NewsArticleCard243 responseFromGoogleLogin={responseFromGoogleLogin}/>}></Route>
                    <Route path="/news/2024/03/26/operation-indravati-ewaste-postal-ballot-farm-gibraltar" element={<NewsArticleCard263 responseFromGoogleLogin={responseFromGoogleLogin}/>}></Route>
                    <Route path="/news/:year/:month/:day/:keywords" element={<NewsArticlePage responseFromGoogleLogin={responseFromGoogleLogin}/>}></Route>
                    <Route path="/pdfedit/:mains_answer_sheet_hash/:user_id/:pdf_name" element={<PdfPageView responseFromGoogleLogin={responseFromGoogleLogin}/>}></Route>
                    <Route path="/pdfview/:mains_answer_sheet_hash/:user_id/:pdf_name" element={<PdfPageViewer responseFromGoogleLogin={responseFromGoogleLogin}/>}></Route>
                    </Routes>
                    
                  )}
                </Router>
                </Suspense>
                </PrimeReactProvider>
                </PdfDataArrayContext.Provider>
                </AudioDataArrayContext.Provider>
              </BannerContext.Provider>
            </SidebarContext.Provider>
          </CalendarContext.Provider>
        </WidgetContext.Provider>
      </UserContext.Provider>
    </GoogleOAuthProvider>
  );
}

export default App;