import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from "react-router-dom";
import './footer.css'

const Footer = () => {
    return (
        <div className='footer-base'>
          {/* <div className="container"> */}
            <div className="footer-row1">
              
              <div className='navbar-brand-img'>
                <Link to='/'>
                <img src="/images/mainLogo.png" alt="collectorbabu-logo" className='collectorbabu-logo' />
                </Link>
              </div>
           
              <div className='footer-rows'>
                <div className='footer-cols'>
                   <ul className=''>
                    <li className= "ftps text-gray-800"><Link to="/ai-answer-review">AI Answer Review</Link></li>
                    <li className= "ftps text-gray-800"><Link to="/upsc-mains-2023-essay-gs-questions-model-answers">Mains 2023</Link></li>
                   </ul>
                </div>
                <div className='footer-cols'>
                  <ul className=''>
                    <li className= "ftps text-gray-800"><Link to="/pyqexplorer">PYQ Explorer</Link></li>
                    <li className= "ftps text-gray-800"><Link to="/360-reader">360 Reader</Link></li>
                  </ul>
                </div>
                <div className='footer-cols'>
                  <ul className=''>
                    <li className= "ftps text-gray-800"><Link to="/upsc-ias-mock-interview">Mock Interview</Link></li>
                    <li className= "ftps text-gray-800"><Link to="/pyq-fast-track">PYQ Fast Track</Link></li>
                  </ul>
                </div>
                <div className='footer-cols justify-end'>
                  <ul className=''>
                    <li className= "ftps text-gray-800"><Link to="/upsc-notes-zero">Notes Zero</Link></li>
                    <li className= "ftps text-gray-800"><Link to="/flash-card">RattafAI</Link></li>
                  </ul>
                </div>
              </div>
              <div className="footer-rows">
                <ul className='footer-contact-items'>
                  <li className= "ftps"><a href="https://t.me/collector_babu_original" target="_blank" rel="noopener noreferrer"><TelegramIcon className="social-icon"/></a></li>
                  <li className= "ftps"><a href="mailto:support@collectorbabu.com"><EmailIcon className="social-icon"/></a></li>
                  <li className= "ftps"><a href="https://www.youtube.com/@collectorbabu_original" target="_blank" rel="noopener noreferrer"><YouTubeIcon className="social-icon"/></a></li>
                </ul>            
               </div>
            </div>
        
            <hr/>
    
    
            <div className='footer-row2 w-full'>
                <div className='footer-policys'>
                <p className='copy-rights'>COPYRIGHT © COLLECTOR BABU 2024</p>
                <ul className='footer-list-itemd '>
                <li className= ""><Link to="/refund">Refund</Link></li>
                <li className= ""><Link to="/cancellation">Cancellation</Link></li>
                    <li className= ""><Link to="/privacy-policy">Privacy policy</Link></li>
                    <li className= ""><Link to="/terms-of-service">Terms of Service</Link></li>
                    {/* <li className= ""><Link to="/cancellation">Cancellation policy</Link></li> */}
                </ul>
                </div>
            </div>
         {/* </div> */}
        </div>
    );
}

export default Footer